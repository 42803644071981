import React, {memo, useCallback, useMemo, useState} from 'react'
import { Project } from './Project'
import { Project as IProject } from '../../../types'
import './Project.css'

interface Category {
    id: string;
    language: string;
    name: string;
}

interface ProjectListProps {
    projects: IProject[];
    header: string;
    categories: Category[];
    language?: string;
    button?: string;
    isPage?: boolean;
}

export const ProjectList = memo((
    {
        projects: allProjects,
        header,
        language,
        button,
        categories,
        isPage = false,
    }: ProjectListProps
) => {
    const [activeCategory, setActiveCategory] = useState<string>()
    const [projects, setProjects] = useState<IProject[]>(
        isPage
            ? allProjects
            : allProjects?.slice(0, 9) ?? []
    )

    const handleChangeCategory = useCallback((categoryId?: string) => () => {
        if (!allProjects) {
            return;
        }

        if (categoryId) {
            const filtedProjects = allProjects.filter((project: IProject) => project.category?.id === categoryId)

            setActiveCategory(categoryId)
            setProjects(isPage ? filtedProjects : filtedProjects.slice(0, 9))

            return;
        }

        setActiveCategory(undefined)

        setProjects(isPage ? allProjects : allProjects.slice(0, 9))
    }, [allProjects])

    return (
        <section className={`projects container ${isPage ? 'projects__page' : ''}`}>
            <h2 className='projects__header projects__page-header container'>{header}</h2>

            <div className='projects__categories'>
                <span
                    className={`projects__category ${!activeCategory ? 'active' : ''}`}
                    onClick={handleChangeCategory()}
                >
                    {language === 'ua' ? 'Всі проекти' : 'All projects'}
                </span>

                {categories.map((category: Category) => (
                    <span
                        key={category.id}
                        className={`projects__category ${activeCategory === category.id ? 'active' : ''}`}
                        onClick={handleChangeCategory(category.id)}
                    >
                        {category.name}
                    </span>
                ))}
            </div>

            <div className='projects__list'>
                {projects.map((project) => (
                    <Project
                        key={project.id}
                        {...project}
                    />
                ))}
            </div>

            {!isPage && (
                <div className='projects__button'>
                    <a href={`${language === 'ua' ? '/projects' : `/${language}/projects`}`}>
                        <button className='btn btn-primary'>{button}</button>
                    </a>
                </div>
            )}
        </section>
    )
})