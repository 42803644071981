import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Project as ProjectProps } from '../../../types'
import 'animate.css'

export const Project = (
    {
        url,
        poster,
        title,
        area,
        date,
        city,
    }: ProjectProps
) => (
    <div className='projects__list-item'>
        <a href={`projects/${url.url}`}>
            <img
                className='image'
                src={`https://n-design.studio${poster.desktop.publicUrl}`}
            />

            <div className='text'>
                <h3>{title}</h3>
                <span>{city}, {area} м², {new Date(date).getFullYear()}</span>
            </div>
        </a>
    </div>
)