import React, {useMemo} from 'react'
import { Helmet } from 'react-helmet'
import { Text } from '../types'
import { MainLayout } from '../components/layouts/Main'
import { SEO } from '../components/ui-components/Seo'
import { ProjectList } from '../components/ui-components/projects/ProjectList'
import { PriceForm } from '../components/ui-components/forms'
import { getTextByKey } from '../utils'

const ProjectsTemplate = (
    {
        pageContext: {
            projects,
            texts,
            meta,
            language,
            categories,
        }
    }
) => {
    const seo = useMemo(() => ({
        title: meta[0].title,
        description: meta[0].description,
        keywords: meta[0].keywords,
        lang: meta[0].lang
	}), [meta])

    const header = useMemo(() => (
        texts.find((text: Text) => text.key === 'projects-header')?.value ?? 'Проекти'
    ), [texts])

    const priceForm = useMemo(() => ({
        header: getTextByKey(texts, 'contacts-form-header'),
        name: getTextByKey(texts, 'contacts-name-placeholder'),
        phone: getTextByKey(texts, 'contacts-phone-placeholder'),
        email: getTextByKey(texts, 'contacts-email-placeholder'),
        button: getTextByKey(texts, 'contacts-button-more'),
        thanks: getTextByKey(texts, 'contacts-button-thanks')
    }), [getTextByKey, texts])

    return (
        <MainLayout
            urlPrefix='projects'
            language={language}
        >
            <Helmet script={[{ innerHTML: `fbq('track', 'ViewContent');` }]}/>

            <SEO {...seo}/>

            {projects.length > 0 && (
                <ProjectList
                    projects={projects}
                    header={header}
                    categories={categories}
                    isPage
                />
            )}

            <PriceForm form={priceForm}/>
        </MainLayout>
    )
}

export default ProjectsTemplate